import logo from './UpThereInteractive.png';
import './App.css';

function Privacy() {
  return (



  <div className="App">
      <header className="App-header2">
      {/*  <img src={logo} className="App-logo" alt="logo" /> */}
        <h1>
        Privacy Policy
        </h1>



        <p>

        UP THERE INTERACTIVE, privacy policy may include handling of data as described below being used by third party tools

        </p>

        <h5>
        Data Linked to you

        </h5>

        <p>

        The following data, which may be collectes and linked to your identity, may be used for the following purposes:

        </p>

        <h7>
        Third-Party Advertising
        </h7>
        <ul>
          <li>
            Purchases - Purchase History
          </li>
          <li>Identifiers - Device ID</li>
          <li>Usage Data - Advertising Data, Other Usage Data</li>
          <li>Other Data - Other data types</li>
        </ul>

        <h7>
        Analytice
        </h7>
        <ul>
          <li>
            Purchases - Purchase History
          </li>
          <li>Identifiers - Device ID</li>
          <li>Usage Data - Advertising Data, Other Usage Data</li>
          <li>Other Data - Other data types</li>
          <li> Diagnostics - Performance Data</li>
        </ul>

        <h7>
        App Functionality
        </h7>
        <ul>
          <li>Identifiers - User ID</li>
          <li>Other Data - Other data types</li>
          <li> Diagnostics - Performance Data</li>
        </ul>



        <h5>
        Data Note Linked to you
        </h5>

        <p>

        The following data, which may be collectes and is not linked to your identity, may be used for the following purposes:

        </p>


        <h7>
      Analytice
        </h7>
        <ul>
          <li>
            Location - Coarse Location
          </li>
          <li>Usage Data - Product Interaction</li>
        </ul>

        <h7>
        Third-Party Advertising
        </h7>
        <ul>
          <li>
            Location - Coarse Location
          </li>
          <li>Usage Data - Product Interaction</li>
        </ul>

        <h7>
        App Functionality
        </h7>
        <ul>
          <li>User Content - Customer Support</li>

        </ul>





        </header>



    </div>
  );
}

export default Privacy;
