import logo from './UpThereInteractive.png';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from './Home'
import Privacy from './Privacy'

function App() {
  return (

    <Router>

    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/privacy-policy" element={ <Privacy />} />
    </Routes>




    </Router>
  );
}

export default App;
