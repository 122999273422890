import logo from './UpThereInteractive.png';
import './App.css';

function Home() {
  return (

  <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Under Construction
        </p>

      </header>

    </div>
  );
}

export default Home;
